:root {
	--color-navbar:#1080b0;
	--color-navbar-text:#ffffff;
	--color-white-text:#ffffff;
	--color-navbar-hover:#248507;
	--color-1:#000000;
	--color-2:#e25c5c;
	--color-3:#15b2d3;
	--color-4:#248507;
	--color-5:#f3872f;
}

html {
	font-size: 14px;
}

body {
	background-color: #ffffff;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
		Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
	font-weight: normal;
	color: #495057;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	margin:0;
	/* margin-bottom: 50px; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 1.5rem 0 1rem 0;
	font-family: inherit;
	font-weight: 600;
	line-height: 1.2;
	color: inherit;
}

h1 {
	font-size: 2.5rem;
}
h2 {
	font-size: 2rem;
}
h3 {
	font-size: 1.75rem;
}
h4 {
	font-size: 1.5rem;
}
h5 {
	font-size: 1.25rem;
}
h6 {
	font-size: 1rem;
}
p {
	line-height: 1.5;
	margin: 0 0 1rem 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

@keyframes pulse {
	0% {
		background-color: rgba(165, 165, 165, 0.1);
	}
	50% {
		background-color: rgba(165, 165, 165, 0.3);
	}
	100% {
		background-color: rgba(165, 165, 165, 0.1);
	}
}

.k-wmax-300px {
	max-width: 300px;
}

.color-blue {
	color: var(--color-navbar);
}
.bg-blue {
	background: var(--color-navbar);
}
.bg-warning {
	background: var(--color-2);
}
.p-button:enabled:hover,
.navbar>.p-menu-list>.p-menuitem,
.navbar {
	background-color: var(--color-navbar);
}
.p-menubar {
	background: var(--color-navbar);
	padding-left:0;
	padding-right:0;
}
.navbar .p-menubar,
.navbar>.p-menu-list > .p-menuitem,
.navbar .p-menubar-root-list>.p-menuitem>.p-menuitem-link>.p-menuitem-text,
.navbar .p-menu-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
	color: var(--color-navbar-text);
}
.navbar .p-menubar-root-list > .p-menuitem:hover {
	background-color: var(--color-navbar-hover);
}
.navbar .p-menubar-root-list {
	width:100%;
	max-width:1200px;
	margin: auto;
	display: flex;
	flex-direction:row;
	justify-content: center;
	background: var(--color-navbar);
	color: var(--color-navbar-text);
}
#popup_menu {
	padding:0;
}
.white-text {
	color: var(--color-white-text);
}
.margin-auto {
	margin: 0 auto;
}
.d-block {
	display: block;
}
.container {
	width:100%;
	max-width:1200px;
	margin: auto;
}
.absolute-0 {
	position:absolute;
	top: 0;
	left:0;
}
.hw-250px {
	height:250px;
	width:250px;
}
.hw-125px {
	height:225px;
	width:225px;
}
.center {
	display:block;
	margin:auto;
	width:60%;
}
.flex-center {
	display: flex;
	justify-content: center;
	align-items:center;
	height:250px;
}
.flex-center:hover {
	border-style:solid;
	border-color: black;
	background-color: silver;
}
.plan-border {
	border-style:solid;
	border-color:#808080;
}
.fontxl-l {
	font-size:24px;
	font-weight:800;
}
.fontxl-l {
	font-size:24px;
	font-weight:200;
}
.fontxl-b {
	font-size:24px;
	font-weight:800;
}
.m-12px {
	margin: 12px;
}
.mb-10px {
	margin-bottom: 10px;
}
.mt-10px {
	margin-top: 10px;
}
.mt-20px {
	margin-top: 20px;
}
.mr-20px {
	margin-right: 20px;
}
.mb-20px {
	margin-bottom: 20px;
}
.ml-20px {
	margin-left: 20px;
}
.mt-360px {
	margin-top: 360px;
}
.w-33pct {
	width:33%;
}
.w-100pct {
	width:100%
}
.kw-100px {
	width:500px;
}
.k-px-0 {
	padding-right:0;
	padding-left:0;
}
.k-mb-0 {
	margin-bottom:0;
}
.k-my-0 {
	margin-top:0;
	margin-bottom:0;
}
.p-y-025rem>.p-datatable-wrapper> table tr> td {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
#chess-container {
	width:100vw;
	height:80vh;
}
#admin-articles > .p-card-body {
	padding:0;
}
#admin-articles  .p-card-title {
	padding-top:0.5rem;
}
.container {
	width:100%;
	max-width:1200px;
	margin: auto;
}
.small-table .p-datatable .p-datatable-tbody > tr> td,
.p-y-025rem>.p-datatable-wrapper> table tr> td {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
.h-50vh {
	height:50vh;
}
.m-0 {
	margin:0;
}
.fontl {
	font-size: 1.5rem;
	font-weight: 700;
}

.fontl-m {
	font-size: 1.5rem;
	font-weight: 400;
}
.w-50pct {
	width:50%;
}
.no-decoration, .no-decoration:visited {
	 text-decoration:none;
	 color: rgb(0,116,204)  
}
.kcol-mobile {
	width:100%;
	flex: 0 0 auto;
  padding: 0.5rem;
}
@media only screen and (min-width: 992px) {
	.kcol-mobile {
		width:66.7%;
	}
}
